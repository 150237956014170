import * as React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import {
  Box,
  Container,
  Divider,
  Heading
} from "@chakra-ui/react";


interface Props {
  body: string
  description?: string
};


export const MarkdownRenderer: React.FC<Props> = ( props ) => {
  return (
    <Container
      maxW={{
        base: '100%',
        sm: '640px',
        md: '768px',
        lg: '776px'
      }}
      w="100%"
      padding="0"
    >
      <Box
        padding="0 0 84px"
        color="accent.900"
      >
        { props.description &&
          <Heading
            as="h6"
            fontSize="28px"
            fontWeight={ 400 }
            lineHeight="33px"
          >
            { props.description }
          </Heading>
        }
        <MDXRenderer>
          { props.body }
        </MDXRenderer>
      </Box>
      <Divider />
    </Container>
  )
}
