import * as React from "react";
import {
  Heading
} from "@chakra-ui/react";


export const Heading3: React.FC = ( props ) => {
  return (
    <Heading
      as="h3"
      margin="32px 0"
      fontSize="28px"
      fontWeight={ 400 }
      lineHeight="33px"
      { ...props }
    />
  )
}
