import * as React from "react";
import {
  List as ChakraList
} from "@chakra-ui/react";


export const List: React.FC = ( props ) => {
  return (
    <ChakraList
      margin="32px 0"
      { ...props }
    />
  )
}
