import { extendTheme } from "@chakra-ui/react";
import { StepsStyleConfig as Steps } from "chakra-ui-steps";
import { CalendarDefaultTheme } from "@uselessdev/datepicker";

import "@fontsource/dm-sans";
import "@fontsource/petrona";


const theme = {
  breakpoints: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
  },
  colors: {
    primary: {
      50: '#F94E1911',
      100: '#F94E1926',
      500: '#F94E19FF',
      900: '#F94E19FF',
    },
    accent: {
      900: '#252C52',
    },
    background: {
      cloudburst: '#252C52',
      linkwater: '#EEF3FA',
      linen: '#FBF7F1',
    },
    white: '#FFFFFF',
  },
  components: {
    Calendar: {
      parts: ['calendar'],
    
      baseStyle: {
        calendar: {
          border: 'none',
          rounded: 'none',
          shadow: 'none',
        },
      },
    },
    CalendarControl: {
      parts: ['button'],
    
      baseStyle: {
        button: {
          h: 6,
          px: 2,
          rounded: 'none',
          fontSize: 'sm',
          color: 'white',
          bgColor: 'primary.900',
    
          _hover: {
            opacity: '0.6',
          },
    
          _focus: {
            outline: 'none',
            boxShadow: 'none',
          },
        },
      },
    },
    CalendarDay: {
      baseStyle: {
        _focus: {
          outline: 'none',
          boxShadow: 'none',
        },
      },
      variants: {
        selected: {
          bgColor: 'primary.900',
    
          _hover: {
            bgColor: 'primary.900',
            opacity: '0.6',
          },
        },
      },
    },
    Steps,
  },
  fonts: {
    default: 'DM Sans',
    heading: 'Petrona',
    body: 'DM Sans',
  },
};


export default extendTheme(CalendarDefaultTheme, theme);
