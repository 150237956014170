import { StaticImage } from "gatsby-plugin-image";
import { Heading3, Heading4, List, ListItem, Paragraph } from "../../../../src/common/Markdown";
import * as React from 'react';
export default {
  StaticImage,
  Heading3,
  Heading4,
  List,
  ListItem,
  Paragraph,
  React
};