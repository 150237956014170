/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import * as React from "react";
import { MDXProvider } from "@mdx-js/react";


export const wrapRootElement = ({ element }) => {
  return (
    <MDXProvider>
      { element }
    </MDXProvider>
  )
};
