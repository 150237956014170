import * as React from "react";
import {
  ListItem as ChakraListItem
} from "@chakra-ui/react";


export const ListItem: React.FC = ( props ) => {
  return (
    <ChakraListItem
      { ...props }
    />
  )
}
