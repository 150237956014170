import * as React from "react";
import {
  UnorderedList as ChakraUnorderedList
} from "@chakra-ui/react";


export const UnorderedList: React.FC = ( props ) => {
  return (
    <ChakraUnorderedList
      stylePosition="inside"
      margin="32px 0"
      { ...props }
    />
  )
}
