import * as React from "react";
import {
  Text
} from "@chakra-ui/react";


export const Paragraph: React.FC = ( props ) => {
  return (
    <Text
      margin="32px 0"
      fontSize="16px"
      fontWeight={ 400 }
      lineHeight="21px"
      { ...props }
    />
  )
}
