import * as React from "react";
import {
  OrderedList as ChakraOrderedList
} from "@chakra-ui/react";


export const OrderedList: React.FC = ( props ) => {
  return (
    <ChakraOrderedList
      stylePosition="inside"
      margin="32px 0"
      { ...props }
    />
  )
}
