import * as React from "react";
import {
  Heading
} from "@chakra-ui/react";


export const Heading4: React.FC = ( props ) => {
  return (
    <Heading
      as="h4"
      margin="32px 0"
      fontSize="24px"
      fontWeight={ 400 }
      lineHeight="54px"
      { ...props }
    />
  )
}
